import React, { useState, useEffect } from "react"
import { Picture } from "react-responsive-picture"
import { Link } from "gatsby"
import axios from "axios"

import useForm from "../../hooks/useForm"
import ButtonSquareForms from "../../components/buttons/_button_square_forms"
import validate from "../../components/_form_validations/beADealersFormValidationRules"
import QuestionInput from "../../components/_forms/_question_input"
import Checkbox from '../../components/_newsletter/checkbox'
import { useCurrenDevice } from '../../hooks/useCurrentDevice'

import img_girl from "../../../static/assets/images/newsletter/girl_cinsa.png"
import img_girlDesktop from "../../../static/assets/images/newsletter/girl_desktop.png"
import BlogImageSpot from '../../components/svg/blog/blog_image_spot'

import PostRegister from "./post_register"

const Register = () =>{

    const API_URL = process.env.NEWSLETTER_ENDPOINT
    const [device] = useCurrenDevice()
    const [isDesktop, setIsDesktop] = useState(false)
    const [register, setRegister] = useState(true)
    const [terms, setTerms] = useState(false)
    const [termsError, setTermsError] = useState(false)
   
    const myInputs ={
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        email: "",
        phone:"",
        marca: "Cinsa"
    }

    const [send, setSend] = useState(true)
    const { inputs, handleInputChange, handleSubmit, errors } = useForm(myInputs, formValidCallback, validate, send, terms, setTermsError)

    function formValidCallback (errorsForm){
		if(Object.keys(errorsForm).length > 0 || !terms || !send) return

		setSend(false) // valida que el formulario solo se envie una vez

        // console.log('es valido el formulario')
        // console.log('data', inputs)
        axios.post(API_URL, inputs).then(function (response) {
          setRegister(false)  
        })
         .catch(function (error) {
           console.log(error);
         })
      }
      
      const handleCheckBox = (item, type = null) => {
        if (item.checked === undefined) return
    
        if (type === "terms" && item.checked) {
          setTerms(item.checked)
          setTermsError(false)
          return
        } else if(type === "terms" && !item.checked) {
          setTerms(false)
          setTermsError(true)
          return
        }
      }

      useEffect(() =>{
        if(device === "desktopMin" || device === "desktop"){
            setIsDesktop(true)
        }
        if(device === "desktop"){
            document.querySelector('html').classList.add('html-form')
        }

        return ()=>{
            document.querySelector('html').classList.remove('html-form')
        }
      }, [device])

    return(
        register ?
            <div className="register_container">
                <div className="register_container--form">
                    <div className="container_title_register">
                        <p className="register_title">Recibe toda la información  </p>
                        <p className="register_subtitle">En tu Correo</p>
                    </div>
                    <div className="register_image">
                        <div className="imagen_container_spot">
                            <BlogImageSpot className="spot_yellow" />
                            <Picture   
                                className="img_baño"
                                sources={[
                                    {
                                        srcSet: img_girl,
                                        media: "(max-width: 1023px)",
                                    },
                                    {
                                        srcSet: img_girlDesktop,
                                        media: "(min-width: 1024px)",
                                    }
                                ]}
                            />
                            <div className="container_spot-blue">
                                <BlogImageSpot className="spot_blue" /> 
                            </div>
                        </div>
                    </div>   
                    <div className="container_form">
                        <form noValidate className="" onSubmit={handleSubmit} autoComplete="off">
                            <QuestionInput onChange={handleInputChange} _className="input_newsletter" input={inputs.nombre} questionName="Nombre" placeholder="Ej. Juan Manuel"name="nombre" required={true} error={errors.nombre} /> 
                            <QuestionInput onChange={handleInputChange} _className={ isDesktop ? "input_small" : "input_newsletter"} input={inputs.apellidoPaterno} questionName="Apellido Paterno" placeholder="Ej. López"name="apellidoPaterno" required={true} error={errors.apellidoPaterno} /> 
                            <QuestionInput onChange={handleInputChange} _className={ isDesktop ? "input_small" : "input_newsletter"} questionName="Apellido Materno" placeholder="Ej. Mancera" name="apellidoMaterno" required={true} error={errors.apellidoMaterno} />
                            <QuestionInput onChange={handleInputChange} _className={ isDesktop ? "input_small" : "input_newsletter"} questionName="Teléfono" placeholder="Ej. (844) 123 45 67" name="phone" required={true} error={errors.phone} maxlength="10" />
                            <QuestionInput onChange={handleInputChange} _className={ isDesktop ? "input_small" : "input_newsletter"} questionName="Correo electrónico" placeholder="Ej. juan_manuel@correo.com" name="email" type="email" required={true} error={errors.email} />
                            <div className="container_terms-button">
                                <div className="container_terminos">
                                    <Checkbox label="" className="q-terms" value="terms" onClick={item => handleCheckBox(item, "terms")} />
                                    <div className="text-terminos">
                                    <p className="label">
                                        Acepto los <Link to="/aviso-de-privacidad">términos y condiciones</Link> de la política de protección de datos.
                                    </p>
                                    { termsError && <p className="terms_errors">Para continuar por favor acepta nuestros términos y condiciones.</p> }
                                    </div>
                                </div>
                                <div className="container_button">
                                    <ButtonSquareForms text={send ? "Suscribir" : "Enviando..." } background="254, 190, 35" disabled={send ? false : true}  />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="register_image--desktop">
                        <div className="imagen_container_spot">
                            <BlogImageSpot className="spot_yellow" />
                            <Picture   
                                className="img_baño"
                                sources={[
                                    {
                                        srcSet: img_girlDesktop,
                                        media: "(min-width: 1024px)",
                                    }
                                ]}
                            />
                            <div className="container_spot-blue">
                                <BlogImageSpot className="spot_blue" /> 
                            </div>
                        </div>
                    </div> 
            </div> :
                <PostRegister />
    )
}

export default Register
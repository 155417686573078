import React from "react";
import Layout from "../components/layout"

import Register from '../content/newsletter/register'
import "../styles/pages/subscribe/_newsletter.scss" 


const Newsletter = () =>{
    return(
        <Layout buttonShop={false} chat={false}>
          <Register/>
        </Layout>
    )
}

export default Newsletter
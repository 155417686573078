import * as React from "react"

function CheckSVG(props) {
  return (
    <svg width={50} height={50} fill="currentColor" viewBox="0 0 10.5 8.3" aria-label="title" {...props}>
      <title>Seleccionado</title>
      <path d="M3.8 8.3L0 4.6 1.6 3l2.2 2.1L8.9 0l1.6 1.6z" />
    </svg>
  )
}

export default CheckSVG

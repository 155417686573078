import React, { useState } from "react"
import PropTypes,{ string,func, bool, } from "prop-types"

import {StyledCheckbox} from "./checkbox.styles"
import CheckSVG from "../svg/newsletter/check"

const classNameID = `a-checkbox`

export const CheckboxAlign = { left : "LEFT", right : "RIGHT" }

const Checkbox = props => {
  const { value, label="", onClick, className, align=CheckboxAlign.left , checked=false } = props  
  const classReversed= align===CheckboxAlign.right ? "a-checkbox--reversed" : "";
  const id = `toggle_${Math.random().toString().replace(/0\./, '')}`;
  const [isChecked, setIsChecked] = useState(checked);
  
  const handleChange = ((e)=> {
    setIsChecked(!isChecked);    
    onClick(e.target)
  }  )
  
  return (
   
    <StyledCheckbox className={`${classNameID} ${className} ${classReversed}`} 
         htmlFor={id}>
        {label !=="" && align===CheckboxAlign.right ? <p className={`${classNameID}__p`}>{label}</p> : null}
        <div className={`${classNameID}__check ${(isChecked ? "a-checkbox--checked" : "")}`} >
          {isChecked ? 
          <CheckSVG/> : null
          }
        </div>
        <input type="checkbox" onChange={handleChange} value={value} className={`${classNameID}__input`} checked={isChecked} id={id}/>
        {label !=="" && align===CheckboxAlign.left  ? <p className={`${classNameID}__p`}>{label}</p> : null}
    </StyledCheckbox>
    )
}

Checkbox.propTypes = {
    value: string,
    label: string,
    onClick: func,    
    className: string,
    align: PropTypes.oneOf(['LEFT', 'RIGHT']),
    checked: bool    
  }
  
  Checkbox.defaultProps = {
    value: "",
    label: "label",
    onClick: ()=>{},    
    className: "",
    align: StyledCheckbox.left,
    checked: false
  }
export default Checkbox
import React, { useEffect} from 'react'
import CheckSVG from "../../components/svg/newsletter/check"
import Sopot from '../../components/svg/home/help/spots'
import { ButtonSquare } from '../../components/buttons/_button_square';
import { Picture } from 'react-responsive-picture';

//=============
// I M A G E S
//=============
import calendatorXS from "../../../static/assets/images/home/help/cinsa_home_tu_calentador_ideal_deposito_XS.png"
import calendatorSM from "../../../static/assets/images/home/help/cinsa_home_tu_calentador_ideal_deposito_SM.png"
import calendatorXLG from "../../../static/assets/images/home/help/cinsa_home_tu_calentador_ideal_deposito_XLG.png"


//=============
// I M A G E S
//=============

const PostRegister = () => {
    
    useEffect(() =>{
        document.querySelector('html').classList.remove('html-form')
    },[])

    function getImage(xs, sm, lg){
        return(
            <div className="post__register--product__image">
                <Picture
                    sources = {[
                    {
                        srcSet: xs,
                        media: "(max-width: 767px)",
                    },
                    {
                        srcSet: sm,
                        media: "(max-width: 1023px)",
                    },
                    {
                        srcSet: lg,
                        media: "(min-width: 1024px)",
                    }
                    ]}
                />
            </div>
        )
    }

    return (
        <div className="post__register--container">
            <div className="post__register--wrapper">
                <div className="post__register--left">
                    <div className="post__register--header">
                        <div className="post__register--subscribe">
                            <div className="post__register--svg_check"> <CheckSVG/></div>
                                <p> ¡Ya te suscribiste! </p>
                        </div>
                        <div className="help__title__text">
                            <span>¿necesitas</span> ayuda para elegir tu calentador?
                        </div>
                        <div className="help__md-visible">
                            <div className="post__register__desc">
                                <span>Nosotros te ayudamos, solo sigue los pasos y te diremos cuál es el calentador ideal para ti. </span>
                            </div>
                            <ButtonSquare _to="/tu-calentador-ideal" _className="post__register__link" _text="Comenzar" _bg_color="#001f5b"></ButtonSquare>
                        </div>
                    </div>
                </div>
                <div className="post__register--right">
                    <div className="post__register--product__container">
                        {getImage(calendatorXS, calendatorSM, calendatorXLG)}
                        <div className="post__register--product__bg">
                            <Sopot/>
                        </div>
                    </div>
                    <div className="help__md-hidden">
                        <div className="post__register__desc">
                            <span>Nosotros te ayudamos, solo sigue los pasos y te diremos cuál es el calentador ideal para ti. </span>
                        </div>
                        <ButtonSquare _to="/tu-calentador-ideal" _className="post__register__link" _text="Comenzar" _bg_color="#001f5b"></ButtonSquare>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostRegister
import styled from "styled-components"

export const StyledCheckbox = styled.label`
    display: flex;
    margin-top: 0px;
    .a-checkbox__input{
        opacity: 0;
        display: none
    }
    &.a-checkbox--reversed{
        .a-checkbox__check{
            position: absolute;
            right: 12px;
            left: auto;           
        }
        
    }
    a-checkbox__p{
        padding-right: 20px;
    }
    .a-checkbox__check{
        width: 20px;
        height: 20px;        
        margin-right: 10px;
        background-color: none;
        box-sizing: border-box;
        border: 2px solid #a3a8ad;    
        border-radius: 0px;    
        position: relative;
        left: 0;
        margin-left: 0px;
        margin-top: 0px;
      
        @media (max-width:1023px){
            /* width: 22px;
            height: 22px; */
        }
        svg{
            color: #FEBE23;
            width: 15px;
            height: 15px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate( -50%, -50%); 
            @media (min-width:1360px){
            /* width: 10px;
            height: 10px; */
            }
        }
        &.a-checkbox--checked{
            border: 2px solid #a3a8ad;
            background-color: transparent;
        }
        
    }    
`
export const Checked = styled.div`
    width: 11.5px;
    height: 11.5px;
    background-color: #FEBE23;
    position: absolute;
    border-radius: 50%;
    left: 49%;
    top: 50%;
    transform: translate( -50%, -50%); 

    @media (max-width:1023px){
        width: 11.5px;
        height: 11.5px;
        top: 52.6%;
    }
    @media (min-width: 1024px){
        top: 51%;
    }
    @media (min-width: 1360px){
        left: 51%;
    }
    @media (min-width: 1920px){
        left: 48%;
    }
`